import React from "react";

const StripeSuccess = ({ data }) => {
  return (
    <div style={{ padding: "0 40px" }}>
      <h1>You successfully created stripe account</h1>
    </div>
  );
};

export default StripeSuccess;
